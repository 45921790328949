export default function currencyFilter(
  value,
  minimumFractionDigits = 0,
  maximumFractionDigits = 4,
  currency = 'PLN'
) {
  return new Intl.NumberFormat('pl-PL', {
    style: 'currency',
    currency,
    minimumFractionDigits,
    maximumFractionDigits,
  }).format(value)
}
