import api from '@/repositories/Repository'

const resource = '/AccountLogin'

export default {
  login(payload) {
    return api.post(`${resource}/Login`, payload)
  },
  register(payload) {
    return api.post(`${resource}/Register`, {
      ...payload,
      linkForConfirmation: `${window.location.origin}/confirm`,
    })
  },
  confirmAccount(payload) {
    return api.post(`${resource}/AccountConfirmation`, payload)
  },
  refreshToken() {
    return api.post(`${resource}/RefreshToken`)
  },
}
