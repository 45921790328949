export default {
  install(Vue) {
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$rules = {
      emailRequired: [
        v => !!v || 'E-mail is required.',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid.',
      ],
      required: [v => !!v || v === 0 || 'Pole jest wymagane.'],
      percentage: [v => (v >= 0 && v <= 100) || 'Wartość może musi być >= 0 oraz <= 100'],
      greaterThan: num => v => v >= num || `Wartość musi być większa od ${num}`,
      intOnly: v => v % 1 === 0 || 'Musi być liczbą całkowitą',
      maxLength: num => v => v.length <= num || `Maksymalnie ${num} znaków`,
      password: [
        v => v.match(/[a-z]/g) || 'Min 1 mała litera.',
        v => v.match(/[A-Z]/g) || 'Min 1 duża litera.',
        v => v.match(/[0-9]/g) || 'Min 1 cyfra.',
        v => v.match(/[^a-zA-Z\d]/g) || 'Min 1 specjalny symbol.',
        v => v.length >= 8 || 'Min 8 znaków.',
      ],
    }
  },
}
