import notifications from '@/components/Notifications/notifications.json'
import generateID from '@/utils/idGenerator'

export default {
  state: {
    notificationList: [],
  },
  mutations: {
    PUSH_NOTIFICATION(state, notification) {
      const {message, type} = notification
      state.notificationList.push({
        message: notifications[message] || message,
        type,
        timeout: 2000,
        id: generateID(),
      })
    },
    REMOVE_NOTIFICATION(state, id) {
      state.notificationList = state.notificationList.filter(item => item.id !== id)
    },
  },
  actions: {
    showNotification({commit}, payload) {
      commit('PUSH_NOTIFICATION', payload)
    },
  },
}
