import RepositoryFactory from '@/repositories/RepositoryFactory'

const CategoryRepository = RepositoryFactory.get('categories')

export default {
  state: {
    biddingCategories: [],
    allCategories: [],
  },
  mutations: {
    SET_BIDDING_CATEGORIES(state, biddingCategories) {
      state.biddingCategories = biddingCategories
    },
    SET_ALL_CATEGORIES(state, allCategories) {
      state.allCategories = allCategories
    },
  },
  actions: {
    async fetchBiddingCategories({commit}, query) {
      const {data} = await CategoryRepository.getBiddingCategoryList(query)
      commit('SET_BIDDING_CATEGORIES', data.categories)
      return data.totalCount
    },
    async fetchAllCategories({commit}, query) {
      const {data} = await CategoryRepository.getCategoryList({
        ...query,
      })
      commit('SET_ALL_CATEGORIES', data.categories)
      return data.totalCount
    },
  },
  getters: {},
}
