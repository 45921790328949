import usersRepository from '@/repositories/usersRepository'
import accountRepository from '@/repositories/accountRepository'
import userProfileRepository from '@/repositories/userProfileRepository'
import forgotPasswordRepository from '@/repositories/forgotPasswordRepository'
import projectsRepository from '@/repositories/projectsRepository'
import productRepository from '@/repositories/productRepository'
import categoryRepository from '@/repositories/categoryRepository'
import blackListRepository from '@/repositories/blackListRepository'
import reportsRepository from '@/repositories/reportsRepository'
import mappingRepository from '@/repositories/mappingRepository'
import pricingRepository from '@/repositories/pricingRepository'
import promoTextsRepository from '@/repositories/promoTextsRepository'

const repositories = {
  users: usersRepository,
  account: accountRepository,
  profile: userProfileRepository,
  forgotPassword: forgotPasswordRepository,
  projects: projectsRepository,
  products: productRepository,
  categories: categoryRepository,
  blackList: blackListRepository,
  reports: reportsRepository,
  mapping: mappingRepository,
  pricing: pricingRepository,
  promoTexts: promoTextsRepository,
}

const RepositoryFactory = {
  get: name => repositories[name],
}

export default RepositoryFactory
