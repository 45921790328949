import RepositoryFactory from '@/repositories/RepositoryFactory'

const blackListRepository = RepositoryFactory.get('blackList')

export default {
  state: {
    categoryBlackList: [],
    productBlackList: [],
    keywordBlackList: [],
  },
  mutations: {
    SET_CATEGORY_BLACK_LIST(state, categoryBlackList) {
      state.categoryBlackList = categoryBlackList
    },
    SET_PRODUCT_BLACK_LIST(state, productBlackList) {
      state.productBlackList = productBlackList
    },
    SET_KEYWORD_BLACK_LIST(state, keywordBlackList) {
      state.keywordBlackList = keywordBlackList
    },
  },
  actions: {
    async getProductBlackList({commit}, payload) {
      const {data} = await blackListRepository.getBlackList('product', payload)
      commit('SET_PRODUCT_BLACK_LIST', data.blackListedProducts)
      return data.totalCount
    },
    async getCategoryBlackList({commit}, payload) {
      const {data} = await blackListRepository.getBlackList('category', payload)
      commit('SET_CATEGORY_BLACK_LIST', data.blackListedCategories)
      return data.totalCount
    },
    async getKeywordBlackList({commit}, payload) {
      const {data} = await blackListRepository.getBlackList('keyword', payload)
      commit('SET_KEYWORD_BLACK_LIST', data.blackListedKeywords)
      return data.totalCount
    },
  },
}
