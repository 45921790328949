import RepositoryFactory from '@/repositories/RepositoryFactory'

const ProjectsRepository = RepositoryFactory.get('projects')

export default {
  state: {
    projectList: [],
    activeProject: {
      id: 1,
    },
  },
  mutations: {
    SET_PROJECT_LIST(state, projectList) {
      state.projectList = projectList
    },
    SET_ACTIVE_PROJECT(state, newActiveProject) {
      state.activeProject = newActiveProject
    },
  },
  actions: {
    async getProjectList({commit, dispatch, state}) {
      const {data} = await ProjectsRepository.getProjectList()
      commit('SET_PROJECT_LIST', data.projects)
      await dispatch('switchProject', state.activeProject.id)
    },
    async getProjectByID(_, id) {
      const {data} = await ProjectsRepository.getProjectByID(id)
      const project = {
        ...data.result,
        concurrents: data.result.concurrents.map(item => ({
          ...item,
          competitorID: item.id,
        })),
      }
      return project
    },
    switchProject({commit, state}, id) {
      const newActiveProject = state.projectList.find(item => item.id === id)
      commit('SET_ACTIVE_PROJECT', newActiveProject)
    },
    async updateProject(_, payload) {
      await ProjectsRepository.updateProject(payload)
    },
    async createProject(_, payload) {
      await ProjectsRepository.createProject(payload)
    },
  },
  getters: {
    activeProjectID: state => state.activeProject.id,
    activeProjectName: state => state.activeProject.name,
    isGranted: state => accessPropertyName => {
      switch (typeof accessPropertyName) {
        case 'string':
          if (state.activeProject[`${accessPropertyName}`] !== undefined) {
            return state.activeProject[`${accessPropertyName}`]
          }
          return true
        case 'object':
          // eslint-disable-next-line no-case-declarations
          const options = []
          accessPropertyName.forEach(name => options.push(state.activeProject[`${name}`]))
          return options.includes(true)
        default:
          return true
      }
    },
  },
}
