import api from '@/repositories/Repository'

const resource = '/UserManagement'

export default {
  getUsers(params) {
    return api.get(`${resource}/GetUserTablePagedList`, {
      params,
    })
  },
  changeUserStatus(id) {
    return api.post(`${resource}/BlockUser`, `'${id}'`)
  },
}
