import api from '@/repositories/Repository'

const resource = '/Project'

export default {
  getProjectList() {
    return api.get(`${resource}/ProjectList/GetProjectsPagedList`)
  },
  updateProject(payload) {
    return api.post(`${resource}/AddAndUpdateProject/UpdateProject`, payload)
  },
  createProject(payload) {
    return api.put(`${resource}/AddAndUpdateProject/AddNewProject`, payload)
  },
  getProjectByID(projectId) {
    return api.get(`${resource}/ProjectList/GetProjectById`, {params: {projectId}})
  },
}
