import RepositoryFactory from '@/repositories/RepositoryFactory'

const MappingRepository = RepositoryFactory.get('mapping')

export default {
  state: {
    mappingList: [],
  },
  mutations: {
    SET_MAPPING_LIST(state, mappingList) {
      state.mappingList = mappingList
    },
  },
  actions: {
    async getMappingList({commit}, query) {
      const {data} = await MappingRepository.getMappingList(query)
      commit('SET_MAPPING_LIST', data.mappings)
      return data.totalCount
    },
  },
}
