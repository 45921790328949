import api from '@/repositories/Repository'

export default {
  setPromoText(payload) {
    return api.post(`/PromotionText/SetPromotionText/SetPromotionText`, payload, {
      params: {
        projectId: true,
      },
    })
  },
}
