import api from '@/repositories/Repository'

const resource = 'Category'

export default {
  getCategoryList(params) {
    return api.get(`${resource}/CategoryList/GetCategoriesPagedList`, {
      params: {
        ...params,
        projectId: true,
      },
    })
  },
  getBiddingCategoryList(params) {
    return api.get(`${resource}/BiddingCategory/GetBiddingCategoriesPagedList`, {
      params: {
        ...params,
        projectId: true,
      },
    })
  },
  unbidCategory(categoryId) {
    return api.delete(`/unbidding/UnBidding/ManualUnbidProducts`, {
      params: {
        projectId: true,
        categoryId,
      },
      data: [],
    })
  },
  deleteFromBidding(categoryId) {
    return api.delete(`/unbidding/UnBidding/DeleteFromBiddingCategories`, {
      params: {
        projectId: true,
        categoryId,
      },
    })
  },
  bidCategory(id) {
    return api.post(`/bidding/Bidding/ManualBidCategory`, null, {
      params: {
        categoryId: id,
      },
    })
  },
}
