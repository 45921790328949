import Vue from 'vue'
import VueRouter from 'vue-router'
// import store from '@/store/index.js'

const DefaultLayout = () => import('@/layouts/DefaultLayout.vue')

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: DefaultLayout,
    name: 'main',
    meta: {icon: '', hideOnSidebar: true, name: ''},
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        alias: '/',
        meta: {icon: '', name: 'Pulpit', pageName: 'Pulpit'},
        component: () => import('@/views/Dashboard.vue'),
      },
      {
        path: 'profile',
        name: 'Profile',
        meta: {icon: '', hideOnSidebar: true, name: 'Edycja konta', pageName: 'Edycja konta'},
        component: () => import('@/views/Profile.vue'),
      },
    ],
  },
  {
    path: '/bidding',
    meta: {icon: 'gavel', name: 'Licytacje', isGranted: 'bidding'},
    component: DefaultLayout,
    redirect: {path: '/products/all'},
    children: [
      {
        path: 'products',
        name: 'Bidding Products',
        meta: {icon: '', name: 'Produkty', pageName: 'Licytowane produkty'},
        component: () => import('@/views/products/BiddingProducts'),
      },
      {
        path: 'categories',
        name: 'Bidding Categories',
        meta: {icon: '', name: 'Kategorie', pageName: 'Licytowane kategorie'},
        component: () => import('@/views/categories/BiddingCategories'),
      },
      {
        path: 'promo-texts',
        name: 'Promo texts',
        meta: {icon: '', name: 'Teksty promocyjne', pageName: 'Ustawianie tekstów promocyjnych'},
        component: () => import('@/views/PromoTexts'),
      },
    ],
  },
  {
    path: '/reports',
    meta: {
      icon: 'trending-up',
      name: 'Raporty',
      isGranted: ['senutoRaport'],
    },
    component: DefaultLayout,
    children: [
      {
        path: 'senuto',
        name: 'Senuto',
        meta: {
          icon: '',
          name: 'Senuto',
          pageName: 'Raport Senuto',
          isGranted: ['senutoRaport', 'searchNodeRaport', 'tradeWatchRaport', 'exactagRaport'],
        },
        component: () => import('@/views/reports/Senuto'),
      },
      {
        path: 'SearchNode',
        name: 'SearchNode',
        meta: {
          icon: '',
          name: 'SearchNode',
          pageName: 'Raport SearchNode',
          isGranted: 'searchNodeRaport',
        },
        component: () => import('@/views/reports/SearchNode'),
      },
      {
        path: 'tradewatch',
        name: 'TradeWatch',
        meta: {
          icon: '',
          name: 'TradeWatch',
          pageName: 'Raport Tradewatch',
          isGranted: 'tradeWatchRaport',
        },
        component: () => import('@/views/reports/TradeWatch'),
      },
      {
        path: 'exactag',
        name: 'Exactag',
        meta: {
          icon: '',
          name: 'Exactag',
          hideTitle: true,
          pageName: 'Raport Exactag',
          isGranted: 'exactagRaport',
        },
        component: () => import('@/views/reports/Exactag'),
      },
    ],
  },
  {
    path: '/config',
    meta: {icon: 'cog', name: 'Konfiguracja'},
    redirect: {path: '/categories/all'},
    component: DefaultLayout,
    children: [
      {
        path: 'mapping',
        name: 'Mapping',
        meta: {icon: '', name: 'Mapowanie', pageName: 'Mapowanie'},
        component: () => import('@/views/Mapping.vue'),
      },
      {
        path: 'pricing',
        name: 'Pricing',
        meta: {icon: '', name: 'Edycja stawek', pageName: 'Konfiguracja stawek'},
        component: () => import('@/views/Pricing.vue'),
      },
      {
        path: 'products',
        name: 'Products',
        meta: {icon: '', name: 'Wszystkie produkty', pageName: 'Wszystkie produkty'},
        component: () => import('@/views/products/AllProducts.vue'),
      },
      {
        path: 'categories',
        name: 'Categories',
        meta: {icon: '', name: 'Wszystkie kategorie', pageName: 'Wszystkie kategorie'},
        component: () => import('@/views/categories/AllCategories.vue'),
      },
    ],
  },
  {
    path: '/blacklist',
    meta: {icon: 'cancel', name: 'Czarne listy'},
    redirect: {path: '/blacklist/products'},
    component: DefaultLayout,
    children: [
      {
        path: 'categories',
        name: 'Category blacklist',
        meta: {icon: '', name: 'Kategorii', pageName: 'Czarna lista kategorii'},
        component: () => import('@/views/blackLists/CategoryBlackList.vue'),
      },
      {
        path: 'products',
        name: 'Product blacklist',
        meta: {icon: '', name: 'Produktów', pageName: 'Czarna lista produktów'},
        component: () => import('@/views/blackLists/ProductBlackList.vue'),
      },
      {
        path: 'keywords',
        name: 'Keyword blacklist',
        meta: {icon: '', name: 'Słów kluczowych', pageName: 'Czarna lista słów kluczowych'},
        component: () => import('@/views/blackLists/KeywordsBlackList.vue'),
      },
    ],
  },
  {
    path: '/administration',
    meta: {
      icon: 'shield-check',
      name: 'Administracja',
    },
    component: DefaultLayout,
    children: [
      {
        path: 'users',
        name: 'Users',
        meta: {icon: '', name: 'Użytkownicy', pageName: 'Użytkownicy'},
        component: () => import('@/views/Users.vue'),
      },
      {
        path: 'projects',
        name: 'Projects',
        meta: {icon: '', name: 'Projekty', pageName: 'Lista projektów'},
        component: () => import('@/views/Projects.vue'),
      },
      {
        name: 'Edit project',
        path: 'projects/:project_id/edit',
        meta: {
          pageName: 'Edycja projektu',
          hideOnSidebar: true,
        },
        component: () => import('@/views/EditProject.vue'),
      },
      {
        name: 'Create project',
        path: 'projects/create',
        meta: {
          pageName: 'Nowy projekt',
          hideOnSidebar: true,
        },
        component: () => import('@/views/AddProject.vue'),
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    meta: {icon: '', hideOnSidebar: true, name: 'Logowanie'},
    component: () => import('@/views/servicePages/Login.vue'),
  },
  {
    path: '/confirm',
    name: 'Aktywacja konta',
    meta: {icon: '', hideOnSidebar: true, name: 'Aktywacja konta'},
    component: () => import('@/views/servicePages/AccountConfirm.vue'),
  },
  {
    path: '/forgot-password',
    name: 'Resetowanie hasła',
    meta: {icon: '', hideOnSidebar: true, name: 'Resetowanie hasła'},
    component: () => import('@/views/servicePages/ResetPassword.vue'),
  },
  {
    path: '/logout',
    name: 'Logout',
    meta: {icon: '', hideOnSidebar: true, name: 'Wylogowanie'},
    component: () => import('@/views/servicePages/Logout'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
