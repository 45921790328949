import axios from 'axios'
import store from '@/store/index'
import router from '@/router'

const baseDomain = process.env.VUE_APP_BASE_DOMAIN
const baseURL = `${baseDomain}/api`

const api = axios.create({
  baseURL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

const sendErrorEmail = error => {
  return fetch(`InternalServerError/SendInternalServerErrorNotification`, {
    method: 'POST',
    body: JSON.stringify(error),
  })
}

api.interceptors.request.use(config => {
  const {headers} = config
  if (store.getters.token) {
    headers.Authorization = `Bearer ${store.getters.token}`
  }
  if (config?.params?.projectId === true && store.getters.activeProjectID) {
    config.params.projectId = store.getters.activeProjectID
  }
  return config
})

api.interceptors.response.use(
  res => res,
  async error => {
    if (error.response.status === 401) {
      router.push('/logout')
    }
    if (error.response.data.code >= 500) {
      const payload = {
        code: error.response.data.code,
        message: error.response.data.message,
      }
      await sendErrorEmail(payload)
      store.dispatch('showNotification', {
        type: 'error',
        message: 'INTERNAL_SERVER_ERROR',
      })
    }
    throw error
  }
)

export default api
