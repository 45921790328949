import api from '@/repositories/Repository'
// import store from '@/store/index'

const resource = 'Product'

export default {
  getProductList(params) {
    return api.get(`${resource}/ProductList/GetProductsPagedList`, {
      params: {
        ...params,
        projectId: true,
      },
    })
  },
  getBiddingProductList(params) {
    return api.get(`${resource}/BiddingProduct/GetBiddingProductsPagedList`, {
      params: {
        ...params,
        projectId: true,
      },
    })
  },
  bidProduct(payload) {
    const {idList} = payload
    return api.post(`/bidding/Bidding/ManualBidProducts`, idList, {
      params: {
        projectId: true,
      },
    })
  },
  unbidProduct(idList) {
    return api.delete(`/unbidding/UnBidding/ManualUnbidProducts`, {
      params: {
        projectId: true,
      },
      data: idList,
    })
  },
  importProducts(formData) {
    return api.post(`${resource}/ProductFill/UploadProductsFromCeneoRaport`, formData, {
      params: {
        projectId: true,
      },
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  },
}
