<template>
  <div>
    <NotificationItem :snackbar="noty" v-for="noty in notifications" :key="noty.id" />
  </div>
</template>

<script>
import {mapState} from 'vuex'
import NotificationItem from '@/components/Notifications/NotificationItem.vue'

export default {
  components: {
    NotificationItem,
  },
  computed: {
    ...mapState({
      notifications: state => state.notifications.notificationList,
    }),
  },
}
</script>

<style></style>
