import api from '@/repositories/Repository'

const resource = '/ForgotPassword'

export default {
  sendCode(email) {
    return api.post(`${resource}/ForgotPassword`, {
      email,
    })
  },
  verifyCode(payload) {
    return api.post(`${resource}/ValidateForgotPasswordCode`, {...payload})
  },
  newPasswordConfirm(payload) {
    return api.post(`${resource}/ForgotPasswordConfirmation`, {...payload})
  },
}
