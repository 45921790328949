import api from '@/repositories/Repository'

export default {
  getPricingList(params) {
    return api.get(`/Project/BiddingPrice/GetBiddingPricesForProject`, {
      params: {
        ...params,
        projectId: true,
      },
    })
  },
  updatePrice(payload) {
    return api.post(`/Project/BiddingPrice/UpdateBiddingPrice`, payload, {
      params: {
        projectId: true,
      },
    })
  },
}
