import api from '@/repositories/Repository'

export default {
  getMappingList(params) {
    return api.get(`/Mapping/MappingList/GetMappingsPagedList`, {
      params: {
        ...params,
        projectId: true,
      },
    })
  },
  addMapping(payload) {
    return api.put(`/Category/CategoryMapping/AddCategoryMappings`, payload)
  },
  removeMapping(params) {
    return api.delete(`/Category/CategoryMapping/DeleteCategoryMapping`, {params})
  },
  updateMapping(payload) {
    const {mappingId, keyword, categoryId} = payload
    const body = {
      categoryId,
      keyword,
    }
    const params = {mappingId}

    return api.post(`/Category/CategoryMapping/UpdateCategoryMapping`, body, {params})
  },
}
