import RepositoryFactory from '@/repositories/RepositoryFactory'

const PricingRepository = RepositoryFactory.get('pricing')

export default {
  state: {
    pricingList: [],
  },
  mutations: {
    SET_PRICING_LIST(state, pricingList) {
      state.pricingList = pricingList
    },
  },
  actions: {
    async fetchPricingList({commit}, params) {
      const {data} = await PricingRepository.getPricingList(params)
      commit('SET_PRICING_LIST', data.biddingPrices)
      return data.totalCount
    },
  },
}
