import RepositoryFactory from '@/repositories/RepositoryFactory'

const AccountRepository = RepositoryFactory.get('account')
const TOKEN_TERM_OF_VALIDITY = 1000 * 60 * 14 // 14 minutes

export default {
  state: {
    token: localStorage.getItem('token') || '',
  },
  mutations: {
    SET_TOKEN(state, token) {
      state.token = token
    },
    REMOVE_TOKEN(state) {
      state.token = ''
    },
  },
  actions: {
    async login({commit, dispatch}, payload) {
      const {data} = await AccountRepository.login(payload.credentials)
      commit('SET_TOKEN', data)
      localStorage.setItem('token', data)
      if (payload.saveSession) {
        localStorage.setItem('session', true)
        await dispatch('refreshToken')
      }
      return Promise.resolve()
    },
    async refreshToken({commit, dispatch}) {
      if (localStorage.getItem('session') === 'true') {
        try {
          const {data} = await AccountRepository.refreshToken()
          localStorage.setItem('token', data)
          commit('SET_TOKEN', data)
          setTimeout(() => dispatch('refreshToken'), TOKEN_TERM_OF_VALIDITY)
        } catch (e) {
          if (e.response.data === 'CANNOT_LOGIN_USER')
            dispatch(
              'showNotification',
              {
                message: e.response.data,
                type: 'info',
              },
              {root: true}
            )
        }
      }
    },
    logout({commit}) {
      commit('REMOVE_TOKEN')
      localStorage.removeItem('token')
      localStorage.removeItem('session')
      return Promise.resolve()
    },
  },
  getters: {
    token: state => state.token,
  },
}
