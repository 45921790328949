export default function dateFilter(
  value,
  options = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  },
  locale = 'pl-PL'
) {
  const date = value ? new Date(value) : new Date()
  const result = date.toLocaleDateString(locale, options)
  const backendDefault = '01.01.1' // 0001-01-01T00:00:00 == null for guy that code backend D:
  return result === backendDefault ? '-' : result
}
