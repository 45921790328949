import Vue from 'vue'
import validations from '@/plugins/validations'
import VueSweetalert2 from 'vue-sweetalert2'
// filters
import dateFilter from '@/filters/date.filter'
import currencyFilter from '@/filters/currency.filter'

import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import '@/scss/index.scss'

Vue.config.productionTip = false

Vue.filter('date', dateFilter)
Vue.filter('currency', currencyFilter)

Vue.use(validations)
Vue.use(VueSweetalert2)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
