import api from '@/repositories/Repository'

const resource = 'UserProfile'

export default {
  getProfile() {
    return api.get(`${resource}/GetUserProfile`)
  },
  updateProfile(payload) {
    return api.put(`${resource}/UpdateUserProfile`, payload)
  },
  updatePassword(payload) {
    return api.put(`${resource}/UpdatePassword`, payload)
  },
}
