<template>
  <v-app :style="{background: $vuetify.theme.themes.light.background}">
    <NotificationList />
    <router-view />
  </v-app>
</template>

<script>
import NotificationList from '@/components/Notifications/NotificationList.vue'

export default {
  name: 'App',
  components: {
    NotificationList,
  },
}
</script>
<style>
* {
  text-transform: none !important;
}
</style>
