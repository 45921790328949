import api from '@/repositories/Repository'

const resource = '/BlackList'

const types = {
  category: {
    getListURL: `${resource}/CategoryBlackList/GetPagedCategoryBlackList`,
    removeURL: `${resource}/CategoryBlackList/DeleteCategoryFromBlackListWithId`,
    addURL: `${resource}/CategoryBlackList/AddCategoryToBlackList`,
  },
  product: {
    getListURL: `${resource}/ProductBlackList/GetPagedProductBlackList`,
    removeURL: `${resource}/ProductBlackList/DeleteProductFromBlackListWithId`,
    addURL: `${resource}/ProductBlackList/AddProductToBlackList`,
  },
  keyword: {
    getListURL: `${resource}/KeyWordBlackList/GetPagedKeywordBlackList`,
    removeURL: `${resource}/KeyWordBlackList/DeleteKeywordFromBlackListWithId`,
    addURL: `${resource}/KeyWordBlackList/AddKeywordToBlackList`,
  },
}

export default {
  getBlackList(type, params) {
    return api.get(`${types[type].getListURL}`, {
      params: {
        ...params,
        projectId: true,
      },
    })
  },
  removeFromBlackList(type, params) {
    return api.delete(`${types[type].removeURL}`, {
      params: {
        ...params,
        projectId: true,
      },
    })
  },
  addToBlackList(type, payload) {
    return api.put(
      `${types[type].addURL}`,
      {
        ...payload,
      },
      {
        params: {projectId: true},
      }
    )
  },
}
