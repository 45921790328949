import Vue from 'vue'
import Vuex from 'vuex'
import account from '@/store/modules/account'
import projects from '@/store/modules/projects'
import users from '@/store/modules/users'
import userProfile from '@/store/modules/userProfile'
import products from '@/store/modules/products'
import notifications from '@/store/modules/notifications'
import categories from '@/store/modules/categories'
import blackLists from '@/store/modules/blackLists'
import reports from '@/store/modules/reports'
import mapping from '@/store/modules/mapping'
import pricing from '@/store/modules/pricing'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    account,
    projects,
    users,
    userProfile,
    products,
    notifications,
    categories,
    blackLists,
    reports,
    mapping,
    pricing,
  },
})
