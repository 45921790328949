<template>
  <v-snackbar
    @input="onInput($event)"
    :timeout="snackbar.timeout"
    :color="snackbar.type"
    right
    top
    v-model="show"
  >
    {{ snackbar.message }}
  </v-snackbar>
</template>

<script>
import {mapMutations} from 'vuex'

export default {
  props: {
    snackbar: {
      type: Object,
    },
  },
  mounted() {
    this.show = true
  },
  data() {
    return {
      show: false,
    }
  },
  methods: {
    ...mapMutations(['REMOVE_NOTIFICATION']),
    onInput(val) {
      if (!val) {
        this.REMOVE_NOTIFICATION(this.snackbar.id)
      }
    },
  },
}
</script>

<style></style>
