import RepositoryFactory from '@/repositories/RepositoryFactory'

const ProfileRepository = RepositoryFactory.get('profile')

export default {
  state: {
    userData: {
      firstName: '',
      lastName: '',
      email: '',
    },
    editForm: {
      firstName: '',
      lastName: '',
      email: '',
    },
  },
  mutations: {
    SET_USER_DATA(state, userData) {
      state.userData = {
        ...userData,
      }
    },
    SET_USER_FORM(state, editForm) {
      state.editForm = {
        ...editForm,
      }
    },
  },
  actions: {
    async getUserData({commit}) {
      const {data} = await ProfileRepository.getProfile()
      commit('SET_USER_DATA', data)
      commit('SET_USER_FORM', data)
    },
    async updateProfile({commit}, payload) {
      await ProfileRepository.updateProfile(payload)
      commit('SET_USER_DATA', payload)
    },
    async updatePassword(_, payload) {
      await ProfileRepository.updatePassword(payload)
    },
  },
}
