import RepositoryFactory from '@/repositories/RepositoryFactory'

const UsersRepository = RepositoryFactory.get('users')
const AccountRepository = RepositoryFactory.get('account')

export default {
  state: {
    userList: [],
  },
  mutations: {
    SET_USER_LIST(state, userList) {
      state.userList = userList
    },
    ADD_USER(state, user) {
      state.userList.push(user)
    },
  },
  actions: {
    async fetchUserList({commit}, payload) {
      const {data} = await UsersRepository.getUsers(payload.params)
      const {totalCount} = data
      commit('SET_USER_LIST', data.users)
      return Promise.resolve(totalCount)
    },
    async registerUser({commit}, payload) {
      await AccountRepository.register(payload)
      commit('ADD_USER', payload)
      return Promise.resolve()
    },
  },
  getters: {},
}
