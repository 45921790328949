import RepositoryFactory from '@/repositories/RepositoryFactory'

const ReportsRepository = RepositoryFactory.get('reports')
const {methods, reports} = ReportsRepository

export default {
  state: {
    reportHistory: [],
  },
  mutations: {
    SET_REPORT_HISTORY(state, reportHistory) {
      state.reportHistory = reportHistory
    },
  },
  actions: {
    async getReportHistory({commit}, payload) {
      const {report, params} = payload
      const {data} = await methods.getReportHistory(report, params)
      commit('SET_REPORT_HISTORY', data[reports[report].historyData])
      return data.totalCount
    },
    async uploadReport(_, payload) {
      const {report, formData, params} = payload
      await methods.uploadReport(report, formData, params)
    },
  },
  getters: {},
}
