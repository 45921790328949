import RepositoryFactory from '@/repositories/RepositoryFactory'

const ProductRepository = RepositoryFactory.get('products')

export default {
  state: {
    biddingProducts: [],
    allProducts: [],
  },
  mutations: {
    SET_BIDDING_PRODUCTS(state, biddingProducts) {
      state.biddingProducts = biddingProducts
    },
    SET_ALL_PRODUCTS(state, allProducts) {
      state.allProducts = allProducts
    },
  },
  actions: {
    async fetchBiddingProducts({commit}, query) {
      const {data} = await ProductRepository.getBiddingProductList(query)
      commit('SET_BIDDING_PRODUCTS', data.products)
      return data.totalCount
    },
    async fetchAllProducts({commit}, query) {
      const {data} = await ProductRepository.getProductList({
        ...query,
      })
      commit('SET_ALL_PRODUCTS', data.products)
      return data.totalCount
    },
  },
  getters: {},
}
