import Vue from 'vue'
import Vuetify from 'vuetify/lib'

import pl from 'vuetify/es5/locale/pl'

Vue.use(Vuetify)

export default new Vuetify({
  customVariables: ['@/assets/variables.scss'],
  lang: {
    locales: {
      pl: {
        ...pl,
        noDataText: 'Brak wyników',
      },
    },
    current: 'pl',
  },
  theme: {
    dark: false,
    themes: {
      light: {
        brand: '#6200EE',
        background: '#ECEFF1',
      },
      dark: {},
    },
  },
})
