import api from '@/repositories/Repository'

const reports = {
  SEARCH_NODE: {
    historyURL: '/Searchnode/SearchNodeCategorizationHistory/GetSearchNodeCategorizationHistories',
    historyData: 'searchnodeCategorizationHistories',
    uploadURL: '/Searchnode/UploadSearchNodeRaport/UploadSearchNodeRaport',
  },
  SENUTO: {
    historyURL: '/Senuto/SenutoHistories/GetSenutoHistoriesPagedList',
    uploadURL: '/Senuto/UploadSenutoRaport/UploadSenutoRaport',
    historyData: 'senutoHistories',
  },
  TRADEWATCH: {
    historyURL: '/TradeWatch/TradeWatchHistories/GetTradeWatchHistoriesPagedList',
    deleteURL: '/TradeWatch/TradeWatchHistories/DeleteTradeWatchHistory',
    uploadURL: '/TradeWatch/TradeWatchHistories/AddTradeWatchHistory',
  },
  EXACTAG: {
    historyURL: '/Exactag/ExactagHistories/GetExactagHistoriesPagedList',
    historyData: 'exactagHistories',
    uploadURL: '/Exactag/UploadExactagRaport/UploadExactagRaport',
  },
}

const methods = {
  getReportHistory(reportName, params) {
    const url = reports[reportName].historyURL
    return api.get(url, {
      params: {
        ...params,
        projectId: true,
      },
    })
  },
  uploadReport(reportName, formData, params) {
    const url = reports[reportName].uploadURL
    return api.post(url, formData, {
      params: {
        projectId: true,
        ...params,
      },
    })
  },
  deleteTradeWatchHistory(params) {
    const url = reports.TRADEWATCH.deleteURL
    return api.delete(url, {
      params,
    })
  },
  addTradeWatchHistory(data) {
    const url = reports.TRADEWATCH.uploadURL
    return api.put(url, data)
  },
}

export default {methods, reports}
